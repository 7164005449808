import React ,{useState}from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Layout,
    Menu,Button
} from 'antd';
const { Header, Content, Sider } = Layout;
const Navbar = ({collapsed,setCollapsed}) => {
    return (
        <Header
            style={{
                padding: 0,
                background: '#ffff',
            }}
        >
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                }}
            />
        </Header>
    );
};

export default Navbar;
