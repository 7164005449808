import React from 'react'
import {
    Card, Input, Spin, Row, Col, Select
    , Button, Space, Alert
} from "antd";


export default function NoPage() {
    return (
        <Card style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ textAlign: 'center' }}><b>HALAMAN TIDAK DITEMUKAN</b></p>
        </Card>
    )
}
