import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    VideoCameraOutlined,
    DashboardOutlined,
    DeliveredProcedureOutlined,
    DesktopOutlined,
    LogoutOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Link, NavLink,useNavigate } from 'react-router-dom';
import kemiko from "../../kemiko.png";
import { Layout, Menu, Button, theme, Image } from 'antd';
const { Header, Sider, Content } = Layout;


export default function SideBar({ collapsed, menus }) {
    const navigate = useNavigate();
    const renderIcon = (v) => {
        switch (v) {
            case "Dashboard":
                return <DashboardOutlined />
            case "Transaksi":
                return <DeliveredProcedureOutlined />
            case "logout" :
                return <LogoutOutlined />
            default:
                return <FileOutlined />;
        }
    }

    const logout = () =>{
        console.log('ini masuk..');
        // localStorage.clear();
        navigate("/login")

    }
    return (
        <>
        {
            (menus.length > 0) &&
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="demo-logo-vertical" style={{ height: 130, backgroundColor: '#ffff', textAlign: 'center' }}>
                    <img src={kemiko} alt="" width={!collapsed ? '120' : '80'} height={!collapsed ? '120' : '80'} style={{ marginTop: !collapsed ? 15 : 10, marginLeft: !collapsed ? -20 : 0 }} srcset="" />
                </div>
                <Menu theme="dark" 
                defaultSelectedKeys={['1']} 
                mode="inline" 
                icon={<UserOutlined />}
                onClick={(e) => {
                    if (e.key === 'logout') {
                        logout()
                    }
                }}>
                    {menus.map((item) => {
                        if (!item.child) {
                            return (
                                <Menu.Item key={item.direction} icon={renderIcon(item.menu_header)}>
                                    <NavLink to={`${item.direction}`}>{item.menu_header}</NavLink>
                                </Menu.Item>
                            );
                        } else {
                            return (
                                <Menu.SubMenu key={item.key} icon={renderIcon(item.menu_header)} title={item.menu_header}>
                                    {item.child.map((child) => (
                                        <Menu.Item key={child.direction}>
                                            <NavLink to={`${child.direction}`}>{child.child_menu}</NavLink>
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            )
                        }
                    })}
                    <Menu.Item key={'logout'} icon={renderIcon('logout')}>
                        <p>{"Logout"}</p>
                    </Menu.Item>
                </Menu>
            </Sider>
        }
        </>

    )
}
