import React, { useState } from 'react';
import Navbar from './NavBar'
import SideBar from './SideBar';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Image } from 'antd';
const { Header, Sider, Content } = Layout;

export default function Layouts({children}) {
    const [collapsed, setCollapsed] = useState(false);
    const [menu,setMenu] = useState([
        {menu_header:'Dashboard',direction:"/dashboard",child : null},
        {menu_header:'Master',direction:"/dashboard",child : [
            {child_menu : 'Barang', direction:"/barang"},
            {child_menu : 'User', direction:"/user"}
        ]}
    ])
    return (
        <Layout>
            <SideBar collapsed={collapsed} menus={menu} />
            <Layout>
                <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 600,
                        background: '#ffff',
                    }}
                >
                <main>{children}</main>
                </Content>
            </Layout>
        </Layout>
    )
}
