import { useEffect,useState } from "react";
import { BrowserRouter, Routes, Route,HashRouter } from "react-router-dom";
import Layouts from "./components/Layouts";
import Dashboard from "./pages/Dashboard"
import NoPage from "./pages/NoPage"
import LoginPage from "./pages/Login/index"


const switchPage = (path) =>{
  switch (path) {
    case "dashboard":
      return <Dashboard />;
    default:
      return <NoPage/>
  }
}

const cekLogin = () =>{
  const storedData = localStorage.getItem('user');
  console.log('ini data login ',storedData);
  if (storedData) {
    return true
  }
  return false
}

function App() {
  return (
    <div>
      <HashRouter>
       {/* <BrowserRouter> */}
        <Routes>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/" element={cekLogin() ? <Layouts children={switchPage('dashboard')}/> : <LoginPage/>}  />
          <Route path="/dashboard" element={cekLogin() ? <Layouts children={switchPage('dashboard')} /> : <LoginPage/>}  />
          <Route path="/barang" element={cekLogin() ? <Layouts children={switchPage('barang')} /> : <LoginPage/>}  />
          <Route path="*" element={cekLogin() ? <Layouts children={switchPage('notFound')} /> : <LoginPage/>} />
        </Routes>
       {/* </BrowserRouter> */}
      </HashRouter>
    </div>
  );
}

export default App;
