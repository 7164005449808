import React, { useState } from 'react'
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './login.css'; // File CSS untuk styling khusus
import { Link, useNavigate, useParams,Redirect  } from "react-router-dom";
import axios from 'axios';


export default function LoginPage() {
    const navigate = useNavigate();
    const onFinish = (values) => {
        console.log('Received values:', values);
        localStorage.setItem('user', JSON.stringify(values));
        navigate("/dashboard")
    };
    return (
        <div className="login-container">
            <Card className="login-card" title="Login">
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
